import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
import {
  Section,
  SectionPageTitle,
  BreadCrumb,
  SectionDescription,
} from "../components/Section"
import CallNowSection from "../components/CallNow"
import { StaticImage } from "gatsby-plugin-image"
import PrivacyPolicy from "../components/Content/PrivacyPolicy"

const BackgroundImage = styled.div`
  height: 100%;
  width: 50%;
  position: absolute;
  top: 0px;
  right: 0;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    img {
      @media (min-width: 1600px) {
        object-position: left center !important;
      }
    }
  }
`
const PrivacyPolicyPage = ({ location }) => {
  return (
    <Layout location={location} >
      <Seo title="Privacy Policy - Coast to Coast Carports" description="Learn about our commitment to protecting your information through our detailed privacy policy. Your privacy is our priority." />
      <BreadCrumb>
        <div className="container">
          <Link to="/">Home / </Link>
          <span>Privacy Policy</span>
        </div>
      </BreadCrumb>
      <Section pt="215px" pb="90px" xpt="120px" xpb="60px" bgColor="#FAFAFA">
        <BackgroundImage>
          <StaticImage
            src="../images/featured-articles.png"
            alt="featured-articles"
          />
        </BackgroundImage>
        <div className="container">
          <SectionPageTitle textAlign="left">Privacy Policy</SectionPageTitle>
          <SectionDescription textAlign="left">
            This Application collects some Personal Data from its Users.
          </SectionDescription>
        </div>
      </Section>
      <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#F2F4F9">
        <div className="container mw-1120">
          <PrivacyPolicy />
        </div>
      </Section>
      <CallNowSection />
    </Layout>
  )
}

export default PrivacyPolicyPage
